export const ERROR_TEXT = "Invalid OTP, please try again.";
export const MAX_OTP_LENGTH = 6;
export const LOCAL_ENV = "dev.";
export let ENV;
switch (window.location.hostname) {
  case "localhost":
    ENV = LOCAL_ENV;
    break;

  case "dev.ftdr.co":
  case "dev.ahs.co":
  case "dev.hsa.co":
  case "dev.ahspro.co":
  case "dev.hsapro.co":
    ENV = "dev.";
    break;

  case "test.ftdr.co":
  case "test.ahs.co":
  case "test.hsa.co":
  case "test.ahspro.co":
  case "test.hsapro.co":
    ENV = "test.";
    break;

  case "staging.ftdr.co":
  case "staging.ahs.co":
  case "staging.hsa.co":
  case "staging.ahspro.co":
  case "staging.hsapro.co":
    ENV = "staging.";
    break;

  case "ftdr.co":
  case "ahs.co":
  case "hsa.co":
  case "ahspro.co":
  case "hsapro.co":
    ENV = "prod.";
    break;

  default:
    ENV = "dev.";
    break;
}
export const INPUT_HINT = `Please enter the ${MAX_OTP_LENGTH}-digit OTP above. `;
export const LINK_EXPIRED_TEXT = "The link is invalid or has expired.";
export const HYDRATE_SHORTURL_REQUEST_URL = `https://${ENV}apis.frontdoorhome.com/shortlink/v1/hydrate-short-url?proto_body=`;
export const SHORTENURL_REQUEST_URL = `https://${ENV}apis.frontdoorhome.com/shortlink/v1/shorten-url`;
export const GET_TENANTID_REQUEST_URL = `https://${ENV}apis.frontdoorhome.com/shortlink/v1/tenant-id?proto_body=`;
export const LINK_EXPIRED_CODE = "RDS_0008";
export const LINK_EXISTS_CODE = "SHORTLINK_BLE_0024";
export const INVALID_URL_CODE = "SHORTLINK_BLE_0025";
export const ERROR_CODE = "SHORTLINK_BLE_0026";
export const SHORTURL_EXISTS = "SHORTLINK_BLE_0027";
export const INVALID_QUERY_PARAMS_ERROR = "SHORTLINK_BLE_0029";
export const NUMBERS_REGEX = /^[0-9\b]+$/;
export const VANITY_REGEX = /^[a-z0-9-_\b]+$/i;
export const GENERAL_ERROR_MSG = "Something went wrong. Please try again.";
